import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/Seo";

import rickP1 from "../assets/images/blog/rick-img-one.jpeg";
import rickP2 from "../assets/images/blog/rick-img-two.jpeg";
import rickP3 from "../assets/images/blog/rick-img-three.jpeg";
import rickP4 from "../assets/images/blog/rick-img-four.jpeg";
import rickP5 from "../assets/images/blog/rick-img-five.jpeg";
import rickP6 from "../assets/images/blog/rick-img-six.jpeg";
import rickP7 from "../assets/images/blog/rick-img-seven.jpg";
import rickP8 from "../assets/images/blog/rick-img-eight.jpeg";

const Blog = props => (
  <Layout>
    <SEO
      title="Obsidian LA Tattoo Blog"
      description="Articles by Obsidian Los Angles"
      keywords={[
        `tattoos in los angeles blog`,
        `los angeles tattoo artists`,
        `obsidian la tattoo`,
        `tattoos los angeles`,
        `la tattoo studios`
      ]}
    />
    <div id="main">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h2>Articles by Obsidian</h2>
          </header>
          <h4>
            PROUD to be a Tattooer: Notes on Tattooing and the LGBT Community{" "}
          </h4>
          <h5>
            by Rick Schenk &nbsp;
            <a
              href="https://www.instagram.com/rickschenktattoo/?hl=en"
              className="icon alt fa-instagram"
            >
              <span className="label">Instagram</span>
              @rickschenktattoo
            </a>
          </h5>

          <div className="grid-wrapper">
            <div className="col-6">
              <span className="image fit">
                <img src={rickP1} alt="" />
              </span>
              <i>Self portrait tattoo. Laughing all the way to the bank</i>
            </div>
          </div>
          <blockquote>
            disclaimer: I am a cisgender white homosexual male tattooer. Because
            I can only write about what i know and can relate to authentically,
            this essay is heavy on information and opinions on gay men
            specifically. That should not suggest a favorable bias toward them
            over say, lesbians or bisexuals, there is just more information
            readily available. Thank you.
          </blockquote>
          <hr />

          <p>
            June is fast approaching and communities all over the world will be
            celebrating PRIDE. June is chosen to commemorate the Stonewall
            Riots, two days of violent protests between the NYPD and more than
            1000 members of the LGBT community on June 28 and 29, 1969 in New
            York City’s West Village. The commotion was in response to a police
            raid at The Stonewall Inn, a gay bar that was frequently a target of
            police harassment of gay people. (They also have free popcorn, but I
            can’t imagine that’s what the cops were upset about.) The Stonewall
            Riots are almost unanimously agreed upon as the event the ignited
            the trajectory of the LGBT rights movement. Gay Americans (and from
            all over the world) have certainly come a long way toward equality
            with heterosexuals and cisgendered individuals in the past fifty
            years, and so too has another stigmatized subculture- tattooed
            people.
          </p>
          <hr />
          <div className="grid-wrapper">
            <div className="col-7">
              <span className="image fit">
                <img src={rickP2} alt="Protesters at The Stonewall Riots" />
              </span>
              <p>Protesters at The Stonewall Riots </p>
            </div>

            <div className="col-5">
              <span className="image fit">
                <img src={rickP3} alt="" />
              </span>
              <i> Phil sparrow</i>
            </div>
          </div>
          <hr />
          <p>
            {" "}
            Obviously, both tattooing and homosexuality have (co)existed since
            man first started banging tools, but for this essay, I’m going to
            date the beginning July 23, 1909, the birthdate of Samuel Steward,
            who will go on to shape modern tattooing in ways few others have.
            (And have more wild gay sex than any one man should be lucky enough
            to have participated in.) #bigleoenergy Steward was many things
            during his long life. He taught English at many prestigious
            universities until becoming a tattoo artist full time in Chicago in
            the 1950s. His friends and mentors included iconic LGBT champions
            Gertrude Stein, Alfred Kinsey, and the artist Etienne. He kept
            secret journals and files detailing all of his sexual encounters,
            complete with coded language regarding specific sex acts, physical
            descriptions of his lovers, and even sometimes pubic hair taped on
            to the cards! (I was lucky enough to see these and much more at an
            exhibition honoring him at The Museum of Sex in NYC a few years
            back.) Some of the men listed on the cards were big names like
            Valentino and Rock Hudson.
          </p>
          <hr />
          <p>
            <span className="image left">
              <img src={rickP4} alt="" />
              <i> A promo card for Phil Sparrow </i>
            </span>
            As a tattooer, Steward used the pseudonym Phil Sparrow, and would go
            on to teach heavy hitters Don “Ed” Hardy and Cliff Raven (another
            pioneer of queer tattooing) to tattoo in his preferred style, a
            hybrid of traditional Americana and Japanese designs. (Sparrow
            himself was mentored by the legendary tattoo artist Armund Dietzel.){" "}
          </p>
          <hr />
          <p>
            Eventually, Sparrow would go on to be the official tattoo artist for
            San Francisco’s Hell’s Angels biker gang, while simultaneously
            building the San Francisco leather scene into what is remains today,
            and producing the pornography that came along with it. For more
            information, I highly recommended his book Bad Boys and Tough
            Tattoos, where he recalls such thoughts that gays don’t get tattooed
            because they are too vain to ‘ruin’ their bodies, and that the very
            nature of gay relationships is such that a queer would never be able
            to commit to something as permanent as a tattoo. He goes on to
            explain this reluctance came to a screeching halt upon the release
            of the 1954 Marlon Brando film “The Wild One”, which, according to
            him, single-handedly birthed the archetypal gay leather man. This,
            he continues, is when openly gay men started getting violent imagery
            such as panthers, skulls, and daggers. This eventually would
            culminate with leather daddies and tribal tattoos becoming near
            synonymous in the 1980s and 1990s. (In that book, he also shares a
            story of a stranger ejaculating on him while he was tattooing his
            penis. If I had a nickel...) Samuel Steward died at the age of 84 in
            Berkeley from pulmonary disease.{" "}
          </p>
          <hr />
          <p>
            This in not to say that the tattoo industry was an especially
            welcoming place for homosexuals is the last half of the 20th
            Century. Like sex, gays will always find a way to get what they
            want, even if it’s dangerous or socially rejected. And we wanted
            tattoos. The seventies and eighties were all about exhibitionism in
            gay subcultures. With so much flaunting and showing off, onscreen or
            on the street, gay men decorated their bodies as means of adornment
            and rebellion.{" "}
          </p>
          <p>
            {" "}
            Of course, not all connections between tattooing and homosexuality
            are positive. Straight people always found a way to degrade us. This
            was especially true during the AIDS crisis of the 1980s. In a 1986
            op-ed in the New York Times, conservative author William F. Buckley
            Jr. proposed marking AIDS sufferers with tattoos, strongly inferring
            a pink triangle (the nazi concentration camp label reserved for
            gays, lesbians and transgendered people during the holocaust) be
            used as the designation. While, he does briefly acknowledge the fact
            HIV/AIDS can be transmitted by anyone, his contempt for homosexuals
            is especially plain. Here’s a sample:
          </p>

          <div className="grid-wrapper">
            <div className="col-6">
              <span className="image fit center">
                <img src={rickP5} alt="" />
              </span>
              <i> Busy Phillips foot tattoo. Love you Dre! </i>
            </div>
          </div>
          <hr />
          <p>
            <blockquote>
              "Everyone detected with AIDS should be tattooed in the upper
              forearm, to protect common-needle users, and on the buttocks, to
              prevent the victimization of other homosexuals.”
            </blockquote>
          </p>
          <hr />
          <div className="grid-wrapper">
            <div className="col-6">
              <span className="image fit center">
                <img
                  src={rickP6}
                  alt="Busy Phillips foot tattoo. Love you Dre!"
                />
              </span>
              <i> Woof</i>
            </div>
          </div>
          <hr />
          <p>
            Clearly more should have been done earlier to combat the tragedy of
            the AIDS epidemic, but enforced labeling of a marginalized community
            is barbaric. What an asshole. In the 2000’s, tattooing went through
            a bit of a renaissance. Much of the stereotype that tattoos were for
            criminals and deviants was lifted, and a wave of art school kids,
            graffiti artists, women, LGBT, and people of color were able to
            learn the craft, either through formal apprenticeships or trial and
            error at home, using equipment that was, for the first time,
            available easily thanks (for better of for worse) to the internet.
          </p>
          <hr />
          <p>
            As people saw tattooers turned into TV stars on Miami Ink and LA
            Ink, the public’s perception of what a tattooer looked like and what
            it meant to be a tattooed person began to change. No longer did
            tattooing look (quite as) white, male, and straight. Most of the
            queer and minority tattooers working today probably first became
            inspired to tattoo from seeing inclusive representation on these
            shows-even if some regard these shows as harmful to the “old guard”
            of tattooing. To which I say, quoting from a tattoo my best friend,
            lesbian tattoo artist Dre Li, of Dallas, recently put on Actress
            compatriots) steer tattooing in the next decade..
          </p>
          <hr />
          <p>
            Today one need not look far to find authentic inclusivity in the
            tattoo world. Instagram accounts such as @queertattooers and
            @queerbodmodcompass curate and publish work from LGBTQIA+ artists
            around the world daily. (Myself included, thank you very much.)
            Entire tattoo shops are dedicated to serving clients in a space safe
            for queer people and anyone that has feared the stereotypical tattoo
            experience of being persecuted or stigmatized. I want to say that
            personally, I have never encountered a directly homophobic
            experience in a tattoo shop, but clearly it exists. I’m not saying
            that segregation is the answer, but some of these shops, including
            UNIV ERSE in Berlin and Valentine’s Tattoo in Seattle do beautiful
            work and are offering a truly safe and supportive environment for
            their clientele that maybe doesn’t feel comfortable unclothed,
            alone, and vulnerable in a busy street shop. I’m lucky to work with
            a crew of folks with diverse identities. I have always been welcomed
            as a tattooer based on my merits, and not my sexuality. That’s all
            equality is. As it says on Valentine’s website, “NO SEXIST,
            HOMOPHOBIC, RACIST, OR HATEFUL VIBES WILL BE TOLERATED.” Now, that
            just seems like common decency and good business to me. Rainbow
            money is still green. This concludes today’s history lesson. If
            you’ve stuck with me this far, I dare say you may have learned
            something or even enjoyed yourself. Diversity and representation
            will continue to grow in this business, thanks to pioneers like Phil
            Sparrow. The sky is the limit, and I look forward to seeing where my
            brothers and sisters (and gender non-conforming compatriots) steer
            tattooing in the next decade.
            <hr />
            <div className="grid-wrapper">
              <div className="col-6">
                <span className="image fit">
                  <img src={rickP7} alt="Woof" />
                </span>
                I’m just happy to be invited on the trip. Happy Pride everyone!
              </div>
            </div>
            <hr />
          </p>
          <div className="grid-wrapper">
            <div className="col-12">
              <span className="image fit ">
                <img src={rickP8} alt="blog pic 8" />
              </span>
            </div>
          </div>
          <hr />
          <a
            href="/artist-rick"
            onClick={props.onToggleMenu}
            className="button special fit"
          >
            Checkout Ricks Work
          </a>
        </div>
      </section>
    </div>
  </Layout>
);

export default Blog;
